import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignUpForm from '../pages/SignUpForm';

const Routers = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<SignUpForm />} />
                <Route exact path="/signup" element={<SignUpForm />} />

            </Routes>
        </Router>
    )
}
export default Routers;
import React from "react"
import Routers from "./router/index"
import "bootstrap/dist/css/bootstrap.min.css"
import "./css/fontStyle.css"
import "./App.css"

const App = () => {
  return (
    <>
      <Routers />
    </>
  )
}
export default App

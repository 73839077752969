export const validEmailID =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const API_URL = "https://ozn31ermlc.execute-api.ap-south-1.amazonaws.com/dev/lcuenquiry"
export const mobileNumValid = /^[0-9]{10}$/

export function isValidDate(date) {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    console.log("date-->", date)
    if (isNaN(date.getTime())) {
      return false
    } else {
      return true
    }
  }
}

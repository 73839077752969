/* eslint-disable eqeqeq */
import React, { useState } from "react"
import { Container, Row, Col, Button, Alert, Spinner } from "react-bootstrap"
import axios from "axios"
import Logo from "../images/logo.png"
import Company1 from "../images/smh.png"
import Company2 from "../images/review_company.png"
import Company3 from "../images/adviser.svg"
import Company4 from "../images/news_company.svg"
import Company5 from "../images/company_name.png"
import Company6 from "../images/mail_company.png"
// import PiggyBank from '../images/piggy_bank.svg';
// import BrokerImage from '../images/broker_icon.svg';
// import HouseIcon from '../images/house_icon.svg';
import MagnifyingGlass from "../images/Hashching_Magnifying_Glass.png"
import { validEmailID, API_URL, mobileNumValid, isValidDate } from "../constant"
import Form from "react-bootstrap/Form"
import OTPVerification from "../component/OTPVerification"
import UIModal from "../component/reusable/UIModal"
import { async } from "q"
import UILoader from "../component/reusable/UILoader"
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import Footer from "../component/Footer"
import { message } from 'antd';
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney"

const SignUpForm = () => {
  const { Option } = Select;
  const [name, setName] = useState("")
  const [emailId, setEmailId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [bestDaytocontact, setBestDaytoContact] = useState("")
  const [bestTimeToContact, setBestTimeToContact] = useState("")
  const [successResponse, setSuccessResponse] = useState(false)
  const [errorRes, setErrorRes] = useState(false)

  const [nameValid, setNameValid] = useState(false)
  const [emailIdValid, setEmailIdValid] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [isValidPhone, setInValidPhone] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const [bestdaytocontactInValid, setBestDaytoContactValid] = useState(false)
  const [isBestTimeToContactInValid, setBestTimeToContactValid] = useState(false)
  const [isInValidDayToContact, setIsInValidDayToContact] = useState(false)
  const [verficationModalOpen, setVerificationModalOpen] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [date, setDate] = useState("")

  const formvalidate = () => {
    if (name === "" || name === undefined || name === null) {
      setNameValid(true)
    }
    if (emailId === "" || emailId === undefined || emailId === null) {
      setEmailIdValid(true)
    }
    if (phoneNumber === "" || phoneNumber === undefined || phoneNumber === null) {
      setPhoneValid(true)
    }
    if (mobileNumValid.test(phoneNumber) == false && phoneNumber !== "") {
      setInValidPhone(true)
    }
    if (bestDaytocontact === "" || bestDaytocontact === undefined || bestDaytocontact === null) {
      setBestDaytoContactValid(true)
    }
    if (bestTimeToContact === "" || bestTimeToContact === undefined || bestTimeToContact === null) {
      setBestTimeToContactValid(true)
    }
    // if (!isValidDate(bestDaytocontact) && bestDaytocontact !== "") {
    //   setIsInValidDayToContact(true)
    //   setBestDaytoContactValid(false)
    // }

    if (validEmailID.test(String(emailId).toLowerCase()) == false && emailId != "") {
      setValidEmail(true)
    }
    if (
      !name ||
      !emailId ||
      !phoneNumber ||
      !bestDaytocontact ||
      !bestTimeToContact ||
      // !isValidDate(bestDaytocontact) ||
      validEmailID.test(String(emailId).toLowerCase()) == false ||
      mobileNumValid.test(phoneNumber) == false
    ) {
      return false
    }
    return true

  }

  const handleNameChange = (e) => {
    setName(e.target.value)
    setNameValid(false)
  }
  const handleEmailChange = (e) => {
    setEmailId(e.target.value)
    setEmailIdValid(false)
    setValidEmail(false)

  }

  const handleBestDaytoContact = (date, dateString) => {
    setDate(date);
    setBestDaytoContactValid(false)
    setIsInValidDayToContact(false)
    setBestDaytoContact(dateString)
  }

  const handleBestTimeToContact = (value) => {
    setBestTimeToContactValid(false);
    setBestTimeToContact(value)
  }

  const handlePhoneNumber = (e) => {
    const inputValue = e.target.value;

    // Remove non-numeric characters
    const numericValue = inputValue.replace(/\D/g, '');

    // Limit to 10 digits
    const truncatedValue = numericValue.slice(0, 10);

    setPhoneNumber(truncatedValue);
    setPhoneValid(false)
    setInValidPhone(false)
  }

  const createForm = async (event) => {
    console.log("[createForm]called")
    event.preventDefault()
    if (formvalidate()) {
      console.log("[createForm]called")
      try {
        setVerificationModalOpen(true)
        await sendOTP()
      } catch (err) {
        console.log("err", err)
      }
    }
  }

  const handleOtpVerification = async (otp) => {
    setBtnLoader(true)
    const payload = {
      phonenumber: phoneNumber,
      otp: otp
    }
    try {
      const response = await axios.post(
        `${API_URL}/submit-otp`,
        payload
      )
      if (response.data.status == true) {
        setVerificationModalOpen(false)
        const data = {
          name: name,
          emailid: emailId,
          phonenumber: phoneNumber,
          datetocontact: bestDaytocontact,
          timetocontact: bestTimeToContact,
        }
        setBtnLoader(true)
        submitForm(data)
      }
      else {
        setBtnLoader(false)
        message.error('Invalid OTP');
      }
    } catch {
      setBtnLoader(false)
    }


  }

  const submitForm = async (payload) => {
    const response = await axios.post(
      `${API_URL}/submit-enquiry`,
      payload
    )
    if (response.data.status === true) {
      setBtnLoader(false)
      message.success('Enquiry submitted successfully!');
      setName("")
      setEmailId("")
      setPhoneNumber("")
      setBestDaytoContact("")
      setBestTimeToContact("")
    }
    else {
      setBtnLoader(false)
    }
  }

  const sendOTP = async () => {
    console.log("called--->")
    const payload = { phonenumber: phoneNumber }
    const response = await axios.post(
      `${API_URL}/send-otp`,
      payload

    )
    console.log("response", response)
  }
  const textStyle = { textAlign: "center", color: "white", width: "80%" }
  const smalltext = { fontSize: "10px", color: "white", listStyle: "none" }
  const exceptThisSymbols = ["e", "E", "+", "."];
  const disabledDate = (current) => {
    // Disable past dates and dates more than three months in the future
    return current && (current < moment().startOf('day') || current > moment().add(3, 'months'));
  };

  const fieldsetStyle = { fontSize: "12px", color: "red" }
  return (
    <section className='signup_container'>
      <div className='logo_container'>
        <a href="http://hashching.com.au" target='_blank' rel='noreferrer'>
          <img src={Logo} alt='logo' className='logo mt-2' />
        </a>
      </div>
      <Container className='pt-2'>


        <Row className='d-flex row-container'>
          <Col lg={6} md={12} sm={12} >
            <div className="form-title">
              <h5 >Want to find out more about this offer?</h5>
              <p style={textStyle}> Leave your details
                below and we’ll connect you with our team for an obligation-free chat.</p>
            </div>
            <div className='right-container'>

              <div className='form-container'>
                <form onSubmit={createForm} autoComplete='off' list='autocompleteOff' noValidate>
                  {/* {successResponse && (
                    <Alert key='success' variant='success'>
                      Successfully reseponse received
                    </Alert>
                  )}
                  {errorRes && (
                    <Alert key='danger' variant='danger'>
                      Invalid OTP
                    </Alert>
                  )} */}
                  <img src={MagnifyingGlass} alt='MagnifyingGlass' className='magnifying-glass' />
                  <Row>
                    <Col md={12} sm={12}>
                      <Form.Group className='mb-1'>
                        {/* Full Name input starts */}
                        <Form.Label htmlFor='fname'>Name</Form.Label>
                        <Form.Control
                          type='text'
                          id='fname'
                          name='fullName'
                          value={name}
                          onChange={handleNameChange}
                          isInvalid={nameValid}
                          maxLength={20}
                          placeholder="Enter Name"
                          onKeyDown={(e) => {
                            // Define the characters you want to allow
                            const allowedChars = /^[a-zA-Z\s]+$/;
                            // Check if the pressed key is not an alphabet character
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />

                        {nameValid && (
                          <Form.Control.Feedback type='invalid'>
                            *This is required Field
                          </Form.Control.Feedback>
                        )}
                        {/* Full Name input ends */}
                      </Form.Group>
                    </Col>

                    <Col md={12} sm={12}>
                      <Form.Group className='mb-1'>
                        <Form.Label htmlFor='email ID'>Email</Form.Label>
                        <Form.Control
                          type='text'
                          id='email ID'
                          name='emailId'
                          value={emailId}
                          onChange={handleEmailChange}
                          isInvalid={emailIdValid || validEmail}
                          maxLength={30}
                          placeholder="example@email.com"
                        />
                        {emailIdValid && (
                          <Form.Control.Feedback type='invalid'>
                            *This is required Field
                          </Form.Control.Feedback>
                        )}
                        {validEmail && (
                          <Form.Control.Feedback type='invalid'>
                            *Email ID is invalid
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={12} sm={12}>
                      <Form.Group className='mb-1'>
                        {/* mobileNumber input starts */}
                        <Form.Label htmlFor='fname'>Mobile</Form.Label>
                        <Form.Control
                          type='text'
                          id='fname'
                          name='phoneNumber'
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                          isInvalid={phoneValid || isValidPhone}
                          max={10}
                          placeholder="0412345678"
                          onKeyDown={(e) => {
                            // Allow only numeric keys (0-9)
                            if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {phoneValid && (
                          <Form.Control.Feedback type='invalid'>
                            *This is required Field
                          </Form.Control.Feedback>
                        )}
                        {isValidPhone && (
                          <Form.Control.Feedback type='invalid'>
                            *Invalid mobile number
                          </Form.Control.Feedback>
                        )}
                        {/* mobileNumber input ends */}
                      </Form.Group>
                    </Col>

                    <Col md={12} sm={12}>
                      <Form.Group className='mb-1' controlId='bestdaytocontact'>
                        <Form.Label>Best Day to Contact</Form.Label>

                        <DatePicker
                          onChange={handleBestDaytoContact}
                          value={bestDaytocontact ? date : null}
                          disabledDate={disabledDate}
                          style={{ width: '100%' }}
                          status={bestdaytocontactInValid ? "error" : ""}
                        />

                        {bestdaytocontactInValid && (
                          <fieldset style={fieldsetStyle}>
                            *This is required Field
                          </fieldset>
                        )}
                        {isInValidDayToContact && (
                          <Form.Control.Feedback type='invalid'>*Invalid Date</Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={12} sm={12}>
                      {/* <Form.Group className='mb-1'>
                        <Form.Label>Best Time to Contact</Form.Label>
                        <Form.Control
                          value={bestTimeToContact}
                          onChange={handleBestTimeToContact}
                          type='time'
                          name='bestdaytocontact'
                          isInvalid={isBestTimeToContactInValid}
                        />
                        {isBestTimeToContactInValid && (
                          <Form.Control.Feedback type='invalid'>
                            *This is required Field
                          </Form.Control.Feedback>
                        )}
                        
                      </Form.Group> */}
                      <Form.Group className='mb-1'>
                        <Form.Label>Best Time to Contact</Form.Label>
                        <Select
                          showSearch={false}
                          style={{ width: "100%" }}
                          placeholder="Select a time"
                          optionFilterProp="children"
                          onChange={handleBestTimeToContact}
                          value={bestTimeToContact ? bestTimeToContact : null}
                          status={isBestTimeToContactInValid ? "error" : ""}

                          options={[
                            {
                              value: '9am-12pm',
                              label: '9 am - 12 pm',
                            },
                            {
                              value: '12pm-2pm',
                              label: '12 pm - 2 pm',
                            },
                            {
                              value: '2pm-4.30pm',
                              label: '2 pm - 4.30 pm',
                            },


                          ]}


                        />

                        {isBestTimeToContactInValid && (
                          <fieldset style={fieldsetStyle}>
                            *This is required Field
                          </fieldset>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12}></Col>
                  </Row>

                  <div className='text-center mt-1'>
                    <Button variant='warning' type='submit'>
                      <div className='mx-3 '>
                        Submit
                      </div>
                    </Button>
                  </div>
                </form>

              </div>

              <div className="form-details" >
                <li style={smalltext}>
                  The service is provided by our certified partner credit
                  provider Laboratories Credit Union (LCU). Hashching does not provide any credit
                  services at all, nor can any Hashching employee provide any advice regarding credit
                  services under any circumstances. By submitting an enquiry you agree to your details
                  being forwarded to our partner credit provider to assist you with your enquiry.
                  As part of Hashching’s partnership with LCU, Hashching may receive a referral
                  commission from LCU.
                </li>
                <ol className="small-text-mobileview">
                  <li >
                    Rates are subject to change. Please refer to our rates and fees page. Credit eligibility
                    criteria, terms & conditions, fees & charges apply. Minimum loan amount $300,000 and
                    only available for owner-occupier, principal & interest loans with an LVR of 80% or less.
                  </li>
                  <br />
                  <li >
                    Comparison rate based on a loan of $150,000 over 25 years.
                    WARNING: This comparison rate is true only for the examples given and may not
                    include all fees and charges. Different terms, fees or other loan amounts might result in
                    a different comparison rate.
                  </li>
                </ol>
              </div>
            </div>

          </Col>

          <Col lg={6} md={12} sm={12} className='consultation_content'>
            <Col lg={8} md={12} >
              <div className="title">
                <h6>
                  Secure the best deal on your home loan, with rates
                  from
                </h6>
                <h3>5.95%pa<sup>1</sup></h3>
                <p>(6.01%pa<sup>2</sup> comparison)</p>
              </div>
            </Col>
            <Row>
              <Col lg={8} md={12}>

                <div className='rating_star'>
                  <ul>
                    <li>Variable and fixed-rate option</li>
                    <li>No ongoing loan fees</li>
                    <li>100% offsets transaction accounts</li>
                    <li>Unlimited extra repayments</li>
                    <li>No redraw fees</li>
                  </ul>
                  <ol className="small-text">
                    <li >
                      Rates are subject to change. Please refer to our rates and fees page. Credit eligibility
                      criteria, terms & conditions, fees & charges apply. Minimum loan amount $300,000 and
                      only available for owner-occupier, principal & interest loans with an LVR of 80% or less.
                    </li>
                    <br />
                    <li >
                      Comparison rate based on a loan of $150,000 over 25 years.
                      WARNING: This comparison rate is true only for the examples given and may not
                      include all fees and charges. Different terms, fees or other loan amounts might result in
                      a different comparison rate.
                    </li>
                  </ol>

                </div>
              </Col>
            </Row>

          </Col>
        </Row>
      </Container >
      {/* Eclipse content */}
      <Footer />
      <UIModal open={verficationModalOpen} closeButton={false} size='sm'>
        <OTPVerification
          handleOtpVerification={handleOtpVerification}
          phoneNumber={phoneNumber}
          // sendPhone={sendPhone}
          resendOtp={sendOTP}
        />
      </UIModal>
      <UIModal open={btnLoader} closeButton={false} size='sm'>
        <UILoader />
      </UIModal>

    </section >
  )
}

export default SignUpForm

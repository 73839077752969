
import Company1 from "../../images/smh.png"
import Company2 from "../../images/review_company.png"
import Company3 from "../../images/adviser.svg"
import Company4 from "../../images/news_company.svg"
import Company5 from "../../images/company_name.png"
import Company6 from "../../images/mail_company.png"
import { Row, Col} from "react-bootstrap"
const Footer = () => {
    return (
      <>
        <footer className="footer-main">
        <div className='ellipse-feature' >
                  <div className='feautre_heading feature-companylist '>
                    <h6>Featured in:</h6>
                    <div className='px-2 company-item'>
                      <Row className="ml-2">
                        <Col xs={6} sm={4} md={4}>
                          <img src={Company1} alt='text' />
                        </Col>
                        <Col xs={6} sm={4} md={4}>
                          <img src={Company2} alt='text' />
                        </Col>
                        <Col xs={6} sm={4} md={4}>
                          <img src={Company3} alt='text' />
                        </Col>
                        <Col xs={6} sm={4} md={4}>
                          <img src={Company4} alt='text' />
                        </Col>
                        <Col xs={6} sm={4} md={4}>
                          <img src={Company5} alt='text' />
                        </Col>
                        <Col xs={6} sm={4} md={4}>
                          <img src={Company6} alt='text' />
                        </Col>
                      </Row>
                    </div>
                  </div>

                </div >
          <div className="footer-container">
            © Hashching Limited ABN 99 600 101 184 | All Rights Reserved.{" "}
            <a className="privacy-policy-link" href='https://www.hashching.com.au/privacy' target='_blank' rel='noreferrer'>
              Privacy Policy
            </a>{" "}
            |{" "}
            <a className="termsofuse-link" href='https://www.hashching.com.au/terms-conditions' target='_blank' rel='noreferrer'>
              Terms of Use
            </a>
          </div>
        </footer>
      </>
    )
  }
export default Footer
  